import React from "react"
import Logo from '../../assets/img/logo-caribe.png'
import Background from "../../assets/img/395647.jpg"
import "../styles/home.scss"

const SectionOne = props => (
  <div class="sectionOne" style={{backgroundImage:"url( " +Background + ")"}}>
    <div class="textArea">
      <img src={Logo} />
      <p class="mainTitle">
        El futuro comienza desde el presente
      </p>
    </div>
  </div>
)

export default SectionOne
